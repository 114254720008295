import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'lsu-layout-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgIf, MatIconModule],
})
export class FeedbackComponent {
  @Input() icon: string | null = null;
  @Input() type: 'primary' | 'danger' | 'warning' | 'success' = 'primary';
}
