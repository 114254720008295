import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgFor, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { UserService } from '@shared/util/user';
import { allowedItems } from '../sidenav.component';
import { SideNavItem } from '../sidenav.model';

@Component({
  selector: 'lsu-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
    ]),
  ],
  imports: [MatListModule, RouterLinkActive, RouterLink, MatTooltipModule, NgStyle, MatIconModule, NgIf, NgFor],
})
export class MenuListItemComponent implements OnInit {
  expanded = false;
  @Input() depth = 0;
  @Input() iconOnly = false;
  children: SideNavItem[] | null = null;
  private _item: SideNavItem = null!;

  get item() {
    return this._item;
  }

  @Input() set item(sideNav: SideNavItem) {
    this._item = sideNav;
    this.children = sideNav.children ? allowedItems(sideNav.children, this.userService.currentOrImpersonateUser.role) : null;
  }

  constructor(private userService: UserService) {}

  async ngOnInit() {
    if (this.children) {
      const path = window.location.pathname.split('?')[0].toLowerCase();
      if (this.children.findIndex((x) => x.routerLink && path.endsWith(x.routerLink.toLowerCase())) !== -1) {
        this.expanded = true;
      }
    }
  }

  onItemClick(evt: MouseEvent, item: SideNavItem): boolean {
    if (typeof item.click === 'function') {
      item.click(evt.ctrlKey);
      return false;
    }

    if (item.children && item.children.length && !this.expanded) {
      this.expanded = true;
      return true;
    }

    if (this.expanded) {
      this.expanded = false;
      return false;
    }
    return true;
  }
}
