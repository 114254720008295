import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDrawerMode, MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { CurrentOrImpersonateUser, UserService } from '@shared/util/user';
import { HeaderComponent, HeaderCustomComponent } from '../header/header.component';
import { DropDownMenu } from '../header/header.model';
import { MenuListItemComponent } from './menu-list-item/menu-list-item.component';
import { SideNav } from './sidenav.model';

export type SidenavConfig = {
  expanded: boolean;
  mode: MatDrawerMode;
};

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'lsu-layout-sidenav-navbar-bottom',
  template: '<ng-content></ng-content>',
  standalone: true,
})
export class SidenavNavbarBottomComponent {}

@Component({
  selector: 'lsu-layout-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'lsuLayoutSidenav',
  imports: [
    HeaderComponent,
    MatButtonModule,
    MatIconModule,
    HeaderCustomComponent,
    MatSidenavModule,
    NgStyle,
    MatListModule,
    forwardRef(() => MenuListItemComponent),
  ],
})
export class SidenavComponent {
  expanded = true;
  serverVersionInfoMaxWidth = '240px';
  minWidth = this.serverVersionInfoMaxWidth;

  @Output() expandedChange = new EventEmitter<boolean>();
  @Input() width?: string;
  @Input() backgroundColor = 'primary';
  @Input() headerTitle = '';
  @Input() headerDropDownMenu?: DropDownMenu;
  @Input() hasModulesMenu = true;

  @Input() set config(config: SidenavConfig) {
    this.expanded = config.expanded;
    !this.expanded ? this.collapseMenu() : this.expandMenu();
    this._config = config;
  }

  get config() {
    return this._config;
  }

  @Input() set sideNav(sideNav: SideNav) {
    this._sideNav = allowedItems(sideNav, this.loggedInUser.role);
  }

  get sideNav(): SideNav {
    return this._sideNav;
  }

  private readonly loggedInUser: CurrentOrImpersonateUser;
  private _sideNav: SideNav = [] as SideNav;
  private _config: SidenavConfig = {
    expanded: true,
    mode: 'side',
  };

  constructor(userService: UserService) {
    this.loggedInUser = userService.currentOrImpersonateUser;
  }

  async onMenuClick(sidenav: MatSidenav) {
    if (!sidenav.opened) {
      await sidenav.open();
      return;
    }
    this.toggleExpand();
  }

  private expandMenu() {
    this.minWidth = this.serverVersionInfoMaxWidth;
    this.expandedChange.next(this.expanded);
  }

  private collapseMenu() {
    this.minWidth = '0px';
    this.expandedChange.next(this.expanded);
  }

  private toggleExpand() {
    this.expanded = !this.expanded;
    !this.expanded ? this.collapseMenu() : this.expandMenu();
  }
}

export function allowedItems(sideNav: SideNav | undefined, userRole: string): SideNav {
  const items = sideNav?.filter(
    (x) =>
      (x.roles?.includes('*') || x.roles?.includes(userRole) || (x.children && allowedItems(x.children, userRole).length > 0)) &&
      !(typeof x.hidden === 'function' ? x.hidden() : x.hidden),
  );
  return items ?? [];
}
