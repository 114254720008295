import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router } from '@angular/router';
import { DropDownItem, DropDownMenu, DropDownSeparator } from './header.model';

@Component({
  selector: 'lsu-layout-header-custom',
  template: '<ng-content></ng-content>',
  standalone: true,
})
export class HeaderCustomComponent {}

@Component({
  selector: 'lsu-layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatToolbarModule, NgClass, NgIf, MatButtonModule, MatMenuModule, MatIconModule, NgFor, MatDividerModule],
})
export class HeaderComponent {
  dropDownItems?: (DropDownItem | DropDownSeparator)[];
  @Input() backgroundColor = 'primary';
  private _headerTitle = '';
  private _dropDownMenu?: DropDownMenu;
  @Input() set headerTitle(headerTitle: string) {
    this._headerTitle = headerTitle;
  }

  get headerTitle(): string {
    return this._headerTitle;
  }

  @Input() set dropDownMenu(dropDownMenu) {
    this._dropDownMenu = dropDownMenu;
    if (dropDownMenu && dropDownMenu.items.length) {
      this.dropDownItems = dropDownMenu.items;
    }
  }

  get dropDownMenu() {
    return this._dropDownMenu;
  }

  constructor(private router: Router) {}
  isDivider = (node: DropDownItem | DropDownSeparator): node is DropDownSeparator => 'isDivider' in node;

  itemClick(evt: Event, item: DropDownItem): void {
    if (typeof item.click === 'function') {
      item.click();
      return;
    }
    this.router.navigate([item.routerLink]);
  }
}
