<div class="vh-100 d-flex flex-column">
  <lsu-layout-header [headerTitle]="headerTitle" [dropDownMenu]="headerDropDownMenu" [backgroundColor]="backgroundColor">
    @if (sideNav.length) {
      <button mat-icon-button class="ms-n1" (click)="onMenuClick(matSidenav)">
        <mat-icon class="menu-icon">menu</mat-icon>
      </button>
    }
    <lsu-layout-header-custom>
      <ng-content select="lsu-layout-header-custom"></ng-content>
    </lsu-layout-header-custom>
  </lsu-layout-header>

  <mat-sidenav-container [autosize]="true" class="h-100">
    <mat-sidenav #matSidenav [mode]="config.mode" opened class="scrollbar-thin" [ngStyle]="{ minWidth: minWidth }">
      @if (sideNav.length) {
        <div class="scrollable-content d-flex flex-column">
          <mat-nav-list>
            @for (nav of sideNav; track $index) {
              <lsu-menu-list-item [item]="nav" [iconOnly]="!expanded"></lsu-menu-list-item>
            }
          </mat-nav-list>
          @if (expanded) {
            <div class="mt-auto" [ngStyle]="{ maxWidth: serverVersionInfoMaxWidth }">
              <ng-content select="lsu-layout-sidenav-navbar-bottom"></ng-content>
            </div>
          }
        </div>
      }
    </mat-sidenav>
    <mat-sidenav-content>
      <main role="main" tabindex="0">
        <div class="p-2">
          <ng-content></ng-content>
        </div>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
